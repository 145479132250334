/* eslint-disable camelcase */
import { notification } from 'antd'
import { all, takeEvery, put, call } from 'redux-saga/effects'
import * as jwt from 'services/jwt'
import actions from './actions'

const jwts = {
  getZeroLoanSummary: jwt.getZeroLoanSummary,
  getZeroLoanRequests: jwt.getZeroLoanRequests,
  getLoans3month2Complete: jwt.getLoans3month2Complete,
  getAllLoanRequests: jwt.getAllLoanRequests,
  getZeroLoanRequestDetail: jwt.getZeroLoanRequestDetail,
  getZeroLoanTransactions: jwt.getZeroLoanTransactions,
  getLoanRepayDetail: jwt.getLoanRepayDetail,
  getZeroMemberLoanTrans: jwt.getZeroMemberLoanTrans,
  getZeroMemberProcurementTrans: jwt.getZeroMemberProcurementTrans,
  approveDeclineLoan: jwt.approveDeclineLoan,
  processLoanRequest: jwt.processLoanRequest,
  loadRefund: jwt.loadRefund,
  closeLoanRequest: jwt.closeLoanRequest,
  archivedLoanRequest: jwt.archivedLoanRequest,
}

export function* GET_ZERO_LOAN_SUMMARY() {
  yield put({
    type: 'loan/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(jwts.getZeroLoanSummary)
  if (success.status) {
    yield put({
      type: 'loan/SET_STATE',
      payload: {
        loading: false,
        loanSummary: success.data,
      },
    })
  }
  if (!success.status) {
    yield put({
      type: 'loan/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.error({
      message: 'Error!',
    })
  }
}

export function* GET_ZERO_LOAN_REQUEST() {
  yield put({
    type: 'loan/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(jwts.getZeroLoanRequests)
  if (success.status) {
    yield put({
      type: 'loan/SET_STATE',
      payload: {
        loading: false,
        pendingLoanRequest: success?.data?.pending || [],
        markedLoanRequest: success?.data?.marked || [],
        approvedLoanRequest: success?.data?.approved || [],
        processingLoanRequest: success?.data?.processing || [],
        disbursedLoanRequest: success?.data?.disbursed || [],
        ongoingLoanRequest: success?.data?.ongoing || [],
        completedLoanRequest: success?.data?.completed || [],
        cancelledLoanRequest: success?.data?.cancelled || [],
      },
    })
  }
  if (!success.status) {
    yield put({
      type: 'loan/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.error({
      message: 'Error!',
    })
  }
}

export function* GET_3_MONTH_LOAN() {
  yield put({
    type: 'loan/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(jwts.getLoans3month2Complete)
  if (success.status) {
    yield put({
      type: 'loan/SET_STATE',
      payload: {
        loading: false,
        Loan2monthCompleted: success?.data || [],
      },
    })
  }
  if (!success.status) {
    yield put({
      type: 'loan/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.error({
      message: 'Error!',
    })
  }
}

export function* GET_ALL_LOAN_REQUEST({payload}) {
  yield put({
    type: 'loan/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(jwts.getAllLoanRequests, payload)
  if (success.status) {
    yield put({
      type: 'loan/SET_STATE',
      payload: {
        loading: false,
        allLoanRequest: success?.data,
      },
    })
  }
  if (!success.status) {
    yield put({
      type: 'loan/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.error({
      message: 'Error!',
    })
  }
}

export function* GET_ZERO_LOAN_DETAIL({ payload }) {
  yield put({
    type: 'loan/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(jwts.getZeroLoanRequestDetail, payload)
  if (success.status) {
    yield put({
      type: 'loan/SET_STATE',
      payload: {
        loading: false,
        loanDetail: success?.data?.loan_details,
        loanAdmin: success?.data?.admins,
        loanGuarantor: success?.data?.guarantors,
        loanDetailStatus: success?.data?.loan_status,
      },
    })
  }
  if (!success.status) {
    yield put({
      type: 'loan/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.error({
      message: 'Error!',
    })
  }
}

export function* GET_ZERO_LOAN_TRANSACTION({ payload }) {
  yield put({
    type: 'loan/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(jwts.getZeroLoanTransactions, payload)
  if (success.status) {
    yield put({
      type: 'loan/SET_STATE',
      payload: {
        loading: false,
        loanTransDetail: {...success?.data?.details, balance: success?.data?.balance},
        loanTransTransaction: success?.data?.transactions || []
      },
    })
  }
  if (!success.status) {
    yield put({
      type: 'loan/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.error({
      message: 'Error!',
    })
  }
}

export function* APPROVE_DECLINE_LOAN({ payload }) {
  yield put({
    type: 'loan/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(jwts.approveDeclineLoan, payload)
  if (success.status) {
    yield put({
      type: 'loan/SET_STATE',
      payload: {
        loading: false,
      },
    })
    yield put({ type: 'loan/GET_ZERO_LOAN_SUMMARY' })
    yield put({ type: 'loan/GET_ZERO_LOAN_REQUEST' })
    yield put({ type: 'loan/GET_ZERO_LOAN_DETAIL', payload: payload?.loan_id })
    notification.success({
      message: success.message,
    })
  }
  if (!success.status) {
    yield put({
      type: 'loan/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.error({
      message: 'Error!',
      description: success.message,
    })
  }
}

export function* PROCESS_LOAN({ payload }) {
  yield put({
    type: 'loan/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(jwts.processLoanRequest, payload)
  if (success.status) {
    yield put({
      type: 'loan/SET_STATE',
      payload: {
        loading: false,
      },
    })
    yield put({ type: 'loan/GET_ZERO_LOAN_SUMMARY' })
    yield put({ type: 'loan/GET_ZERO_LOAN_REQUEST' })
    notification.success({
      message: success.message,
    })
  }
  if (!success.status) {
    yield put({
      type: 'loan/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.error({
      message: 'Error!',
      description: success.message,
    })
  }
}


export function* CLOSE_LOAN({ id, payload }) {
  yield put({
    type: 'loan/SET_STATE',
    payload: {
      loading: true,
    },
  })
  yield put({
    type: 'member/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(jwts.closeLoanRequest, payload)
  if (success.status) {
    yield put({
      type: 'loan/SET_STATE',
      payload: {
        loading: false,
      },
    })
    yield put({
      type: 'member/SET_STATE',
      payload: {
        loading: false,
      },
    })
    yield put({ type: 'loan/GET_ZERO_LOAN_SUMMARY' })
    yield put({ type: 'loan/GET_ZERO_LOAN_REQUEST' })
    notification.success({
      message: success.message,
    })
    if(id){
      yield put({ type: 'member/GET_ZERO_MEMBER_SAVING_DETAIL', payload: id })
      yield put({ type: 'member/GET_ZERO_MEMBER_SAVING_TRANS', payload: { id } })
      yield put({ type: 'member/GET_ZERO_MEMBER_TARGET_DETAIL', payload: id })
      yield put({ type: 'member/GET_ZERO_MEMBER_TARGET_TRANS', payload: id })
      yield put({ type: 'member/GET_ZERO_MEMBER_SHARE_DETAIL', payload: id })
      yield put({ type: 'member/GET_ZERO_MEMBER_SHARE_TRANS', payload: { id } })
      yield put({ type: 'member/GET_ZERO_MEMBER_LOAN_DETAIL', payload: id })
      yield put({ type: 'member/GET_ZERO_MEMBER_LOAN_TRANS', payload: { id } })
      yield put({ type: 'member/GET_ZERO_MEMBER_PROCUREMENT_DETAIL', payload: id })
      yield put({ type: 'member/GET_ZERO_MEMBER_PROCUREMENT_TRANS', payload: { id },})
      yield put({ type: 'member/GET_ZERO_MEMBER_WALLET_TRANS', payload: { id, data:{from_date:'', to_date:''} } })
      yield put({ type: 'member/GET_ZERO_MEMBER_PACKAGE_DEBT', payload: id })
    }
  }
  if (!success.status) {
    yield put({
      type: 'loan/SET_STATE',
      payload: {
        loading: false,
      },
    })
    yield put({
      type: 'member/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.error({
      message: 'Error!',
      description: success.message,
    })
  }
}

export function* ARCHIVED_LOAN({ id, payload }) {
  yield put({
    type: 'loan/SET_STATE',
    payload: {
      loading: true,
    },
  })
  yield put({
    type: 'member/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(jwts.archivedLoanRequest, payload)
  if (success.status) {
    yield put({
      type: 'loan/SET_STATE',
      payload: {
        loading: false,
      },
    })
    yield put({
      type: 'member/SET_STATE',
      payload: {
        loading: false,
      },
    })
    yield put({ type: 'loan/GET_ZERO_LOAN_SUMMARY' })
    yield put({ type: 'loan/GET_ZERO_LOAN_REQUEST' })
    notification.success({
      message: success.message,
    })
    if(id){
      yield put({ type: 'member/GET_ZERO_MEMBER_SAVING_DETAIL', payload: id })
      yield put({ type: 'member/GET_ZERO_MEMBER_SAVING_TRANS', payload: { id } })
      yield put({ type: 'member/GET_ZERO_MEMBER_TARGET_DETAIL', payload: id })
      yield put({ type: 'member/GET_ZERO_MEMBER_TARGET_TRANS', payload: id })
      yield put({ type: 'member/GET_ZERO_MEMBER_SHARE_DETAIL', payload: id })
      yield put({ type: 'member/GET_ZERO_MEMBER_SHARE_TRANS', payload: { id } })
      yield put({ type: 'member/GET_ZERO_MEMBER_LOAN_DETAIL', payload: id })
      yield put({ type: 'member/GET_ZERO_MEMBER_LOAN_TRANS', payload: { id } })
      yield put({ type: 'member/GET_ZERO_MEMBER_PROCUREMENT_DETAIL', payload: id })
      yield put({ type: 'member/GET_ZERO_MEMBER_PROCUREMENT_TRANS', payload: { id },})
      yield put({ type: 'member/GET_ZERO_MEMBER_WALLET_TRANS', payload: { id, data:{from_date:'', to_date:''} } })
      yield put({ type: 'member/GET_ZERO_MEMBER_PACKAGE_DEBT', payload: id })
    }
  }
  if (!success.status) {
    yield put({
      type: 'loan/SET_STATE',
      payload: {
        loading: false,
      },
    })
    yield put({
      type: 'member/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.error({
      message: 'Error!',
      description: success.message,
    })
  }
}

export function* GET_LOAN_REPAY_DETAIL({ payload }) {
  yield put({
    type: 'loan/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(jwts.getLoanRepayDetail, payload)
  if (success.status) {
    yield put({
      type: 'loan/SET_STATE',
      payload: {
        loading: false,
        repayDetail: success?.data,
        loanAmount: success?.loan_amt,
        credit: success?.credit,
        debit: success?.debit,
        filterBalance: success?.filteredBalance,
        amountRepaid: success?.loan_bal
      },
    })
  }
  if (!success.status) {
    yield put({
      type: 'loan/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.error({
      message: 'Error!',
    })
  }
}

export function* REFUND_LOAN({id, payload}) {
  yield put({
    type: 'loan/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(jwts.loadRefund, payload)
  if (success.status) {
    yield put({
      type: 'loan/SET_STATE',
      payload: {
        loading: false,
        closed: false,
      },
    })
    yield put({ type: 'loan/GET_ZERO_LOAN_SUMMARY' })
    yield put({ type: 'loan/GET_ZERO_LOAN_REQUEST' })
    notification.success({
      message: success.message,
    })
    if(id){
      yield put({ type: 'member/GET_ZERO_MEMBER_SAVING_DETAIL', payload: id })
      yield put({ type: 'member/GET_ZERO_MEMBER_SAVING_TRANS', payload: { id } })
      yield put({ type: 'member/GET_ZERO_MEMBER_TARGET_DETAIL', payload: id })
      yield put({ type: 'member/GET_ZERO_MEMBER_TARGET_TRANS', payload: id })
      yield put({ type: 'member/GET_ZERO_MEMBER_SHARE_DETAIL', payload: id })
      yield put({ type: 'member/GET_ZERO_MEMBER_SHARE_TRANS', payload: { id } })
      yield put({ type: 'member/GET_ZERO_MEMBER_LOAN_DETAIL', payload: id })
      yield put({ type: 'member/GET_ZERO_MEMBER_LOAN_TRANS', payload: { id } })
      yield put({ type: 'member/GET_ZERO_MEMBER_PROCUREMENT_DETAIL', payload: id })
      yield put({ type: 'member/GET_ZERO_MEMBER_PROCUREMENT_TRANS', payload: { id },})
      yield put({ type: 'member/GET_ZERO_MEMBER_WALLET_TRANS', payload: { id } })
      yield put({ type: 'member/GET_ZERO_MEMBER_PACKAGE_DEBT', payload: id })
    }
  }
  if (!success.status) {
    yield put({
      type: 'loan/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.error({
      message: 'Error!',
      description: success.message,
    })
  }
}

export function* GET_ALL_LOAN_TRANS({ payload }) {
  yield put({
    type: 'loan/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(jwts.getZeroMemberLoanTrans, payload)
  if (success.status) {
    yield put({
      type: 'loan/SET_STATE',
      payload: {
        loading: false,
        memberLoanTrans: success?.data,
      },
    })
  }
  if (!success.status) {
    yield put({
      type: 'loan/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }
}

export function* GET_ALL_PROC_TRANS({ payload }) {
  yield put({
    type: 'loan/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(jwts.getZeroMemberProcurementTrans, payload)
  if (success.status) {
    yield put({
      type: 'loan/SET_STATE',
      payload: {
        loading: false,
        memberProcureTrans: success?.data,
      },
    })
  }
  if (!success.status) {
    yield put({
      type: 'loan/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }
}

export function* SET_TAB_KEY({payload}) {
  yield put({
    type: 'loan/SET_STATE',
    payload: {
      tabKey: payload,
    },
  })
}

export default function* rootSaga() {
  yield all([takeEvery(actions.SET_TAB_KEY, SET_TAB_KEY)])
  yield all([takeEvery(actions.GET_ZERO_LOAN_SUMMARY, GET_ZERO_LOAN_SUMMARY)])
  yield all([takeEvery(actions.GET_ALL_LOAN_REQUEST, GET_ALL_LOAN_REQUEST)])
  yield all([takeEvery(actions.GET_ZERO_LOAN_REQUEST, GET_ZERO_LOAN_REQUEST)])
  yield all([takeEvery(actions.GET_3_MONTH_LOAN, GET_3_MONTH_LOAN)])
  yield all([takeEvery(actions.GET_ZERO_LOAN_DETAIL, GET_ZERO_LOAN_DETAIL)])
  yield all([takeEvery(actions.GET_ZERO_LOAN_TRANSACTION, GET_ZERO_LOAN_TRANSACTION)])
  yield all([takeEvery(actions.GET_LOAN_REPAY_DETAIL, GET_LOAN_REPAY_DETAIL)])
  yield all([takeEvery(actions.GET_ALL_LOAN_TRANS, GET_ALL_LOAN_TRANS)])
  yield all([takeEvery(actions.GET_ALL_PROC_TRANS, GET_ALL_PROC_TRANS)])
  yield all([takeEvery(actions.APPROVE_DECLINE_LOAN, APPROVE_DECLINE_LOAN)])
  yield all([takeEvery(actions.PROCESS_LOAN, PROCESS_LOAN)])
  yield all([takeEvery(actions.CLOSE_LOAN, CLOSE_LOAN)])
  yield all([takeEvery(actions.ARCHIVED_LOAN, ARCHIVED_LOAN)])
  yield all([takeEvery(actions.REFUND_LOAN, REFUND_LOAN)])
}
